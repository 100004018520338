@use "/src/styles/variables.scss" as *;

.Header {
  font-family: var(--font);
  position: relative;
  display: flex;
  flex-direction: column;
  height: 40rem;
  overflow: hidden;
  margin-top: -5rem;

  @media only screen and (max-width: $s-screen) {
    min-height: calc(90vh - 5rem);
  }

  svg {
    width:120vw;
    height: 100%;
    transition: transform 1s;
  }

  .swiper {
    width: 100vw;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 3;
    width: $l-screen;
    margin: 6rem auto auto;
    @media screen and (max-width: $l-screen) {
    }
    @media screen and (max-width: $m-screen ) {
      flex: 2;
      width: 100%;
    }
  }

  &__text {
    display: flex;
    gap: 1rem;
    flex-direction: column;

    height: 60%;
    width: 800px;
    color: #ffffff;
    @media screen and (max-width: $m-screen) {
      width: 100%;
      height: 100%;
      justify-content: left;
    }
  }
  &__text-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-left: 1rem;

    @media screen and (max-width: 900px) {
        height: 80%;
        justify-content: space-evenly;
      padding: 2rem;
     }
  }
  &__title {
    font-size: 3rem;
    width: 100%;
    font-weight: 500;
    @media screen and (max-width: 900px) {
      font-size: 3rem;
     }
  }
  &__subtitle {
    font-size: 24px;
    width: 100%;
      @media screen and (max-width: 900px) {
          font-size: 20px;
        }
  }
  &__description {
    font-size: 20px;
    width: 100%;
      @media screen and (max-width: 900px) {
          font-size: 16px;
        }
  }
  &__bottom {
    position: relative;
    display: flex;
    width: 100%;
    flex: 1;
    @media screen and (max-width: 900px) {
      flex: 0.4;
    }
    &-bg {
      position: absolute;
      top: 0;
      z-index: -1;
      display: flex;
      flex-direction: row;
      transition: left ease-in 0.25s;
      &--white {
        width: 100vw;
        background: white;
        height: 8.5rem;
      }
      &--none {
        background: none;
        height: 2rem;
      }
    }
  }
  &__big-button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    text-align: center;
    color: var(--dark);
    //background: #ffffff;
    border-right: 1px solid #c4c4c4;
    border-bottom: 1px solid #c4c4c4;
 
   
    &:visited{
      animation: backGroundAnimation 5s infinite;
    }
    
    cursor: pointer;
    font-size: 2rem;
    @media only screen and (max-width: $s-screen) {
          font-size: 1.3rem;
      
      }
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    width: 518px;
    & > button {
      font-size: 20px;
      padding: 7px 20px;
    cursor: pointer;
    }
    @media screen and (max-width: 900px) {
        width: 100%;
        justify-content: center;
        gap: 30px;
    }
    @media screen and (max-width: 400px) {
        flex-wrap: wrap;
        min-height: 167px;
        margin-bottom: 20px;
    }
  }
  &__button {
    &--red {
      background-color: var(--primary);
      border: 1px solid var(--primary);
    }
    &--transparent {
      background-color: transparent;
      border: 1px solid #ffffff;
    }
  }
  &__background-none {
    //animation: backGroundAnimation 1s 1;
    //animation-fill-mode: both;
    background: none;
    color: white;
  }
}

@keyframes backGroundAnimation {
  to {background: none;
    color: #ffffff;}
}
