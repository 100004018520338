@use "/src/styles/variables" as *;

.blockquote {
  max-width: $m-screen;
  font-weight: 400;
  font-size: 2rem;
  line-height: 3rem;
  text-align: center;
  padding: var(--padding-inline);
  margin: 0 auto;
  padding-top: 8rem;
  padding-bottom: 8rem;
  white-space: pre-wrap;
}