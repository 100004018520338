@use "/src/styles/variables.scss" as *;

$slide-width: 270px;//350;
$slide-height: 420px;//520;

.Post {
    cursor: pointer;
    width: $slide-width;
    max-width: 100%;
    height: calc($slide-height);
    -webkit-box-shadow: 0 0 18px 0 rgba(0,0,0,0.1);
    box-shadow: 0 0 18px 0 rgba(0,0,0,0.1);
    padding-bottom: 3rem;
    background-color: var(--white);

  &__Cover {
    position: relative;
    background-position: center;
    background-size: cover;
    width: $slide-width;
    height: $slide-height * 3/5;
    @media screen and (max-width: $s-screen) {
        height: $slide-height * 1/2;
    }
  }

  &__Legend {
    padding: 1rem;
    overflow: hidden;
    height: $slide-height * 2/5;
    display: grid;
    grid-template-columns: 1fr 4.8rem;
    grid-template-rows: 4rem 1fr;
    @media screen and (max-width: $s-screen) {
      display: flex;
      flex-direction: column;
    }
  }
  &__Date {
    text-align: right;
    color: var(--grey);
    font-size: 0.8rem;
    align-items: flex-end;
    justify-content: flex-end;
    @media screen and (max-width: $s-screen) {
      align-items: flex-start;
      justify-content: flex-start;
      text-align: left;
      margin-bottom: 1rem;
    }
  }
  &__Title {
    font-size: 1.2rem;
  }
  &__Short {
    grid-column : 1 / span 2;
    height: 5rem;
    text-overflow: ellipsis;
    font-size: 1.0rem;
    overflow: hidden;

    display: -webkit-box !important;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    white-space: normal;
  }

  &__Buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2rem;
  }
}