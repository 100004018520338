@use "/src/styles/variables" as *;


.EditorJsContent {
  h1, h2, h3, h4 {
    font-weight: 400;
    margin: 0;
    color: var(--blue);
    text-transform: uppercase;
  }

  img {
    max-width: calc(min(600px, 100vw - $padding*2));
    width: auto;
    height: auto;
    display: block;
    margin: 2rem auto;
  }

  table {
    margin: 2rem 0;
    border-collapse: collapse;
    tr {

    }
    td {
      padding: 0.5rem 1rem;
      border: 1px solid var(--grey);
    }
  }

  mark {
    background-color: var(--orange-light);
  }

  a {
    //font-weight: bold;
    color: var(--blue);
    text-decoration: underline;
  }

  code {
    background-color: var(--light-grey);
    display: block;
    padding: 2rem;
    color: var(--dark);
  }

  p {
    font-size: 1rem;
    font-weight: 300;
    margin: 1rem 0;
    line-break: normal;
    overflow-wrap: normal;
    word-break: break-word;
  }

  b {
    color: var(--blue);
  }

  ul {
    margin: 1rem 0;
    font-weight: 300;
    list-style: '-';
    li {
      list-style: none;
      margin-bottom: 0.5rem;
    }
    li::before {
      content: "- ";
      color: var(--blue);
      font-weight: bold;
      padding-right: 0.5rem;
    }
  }

  ol {
    margin: 1rem 0;
    padding-left: 0;
    font-weight: 300;
    list-style: none;
    text-indent: 0.5rem;
    counter-reset: my-awesome-counter;
    li {
      text-indent: 0;
      margin-bottom: 0.5rem;
      counter-increment: my-awesome-counter;
    }
    li::before {
      content: counter(my-awesome-counter) ". ";
      color: var(--blue);
      font-weight: bold;
      padding-right: 0.5rem;
    }
  }
}