@use "/src/styles/variables" as *;

.TeamSection {
  position: relative;

  &__playButton {
    position: absolute;
    top: 4rem;
    right: 1rem;

    @media screen and (max-width: $s-screen){
      top: 2rem;
    }
  }

  &__grid {
    display: grid;
    aspect-ratio: 16/9;
    transition: 0.3s;
    overflow: hidden;

    @media screen and (max-width: $s-screen){
      aspect-ratio: 3/4;
    }

  }

  &__col {
    display: grid;
    transition: 0.3s;

    .TeamSection__person:first-child {
      background-color: #E2E2E2;
    }
    .TeamSection__person:nth-child(2) {
      background-color: #F0F0F0;
    }
    .TeamSection__person:nth-child(3) {
      background-color: #F4F4F4;
    }
  }

  &__col-odd {
    display: grid;
    transition: 0.3s;

    .TeamSection__person:first-child {
      transition: background-color 0.6s;
      background-color: #F0F0F0;
    }
    .TeamSection__person:nth-child(2) {
      transition: background-color 0.6s;
      background-color: #E2E2E2;
    }
    .TeamSection__person:nth-child(3) {
      transition: background-color 0.6s;
      background-color: #F4F4F4;
    }

  }

  &__person {
    padding: 1rem;
    cursor: pointer;
  }


  &__person--red {
    .TeamSection__person-name {
      color: var(--red);
    }
  }
  &__person--blue {
    .TeamSection__person-name {
      color: var(--blue);
    }
  }
  &__person--orange {
    .TeamSection__person-name {
      color: var(--orange);
    }
  }

  &__person:hover {
    opacity: 0.8;
  }

  &__person-name {
    font-size: 1.5rem;
    margin-bottom: 0.3rem;

    @media screen and (max-width: $s-screen){
      font-size: 1rem;
    }

  }

  &__person-tag {
    font-size: 1.2rem;
    @media screen and (max-width: $s-screen){
      font-size: 1rem;
    }

  }

  &__person--selected {

    opacity: 1 !important;


    .TeamSection__person-name {
      color: var(--white) !important;
    }

    .TeamSection__person-tag {
      color: var(--white) !important;
    }
    .TeamSection__person-description {
      padding: 1rem 0;
      font-size: 1rem;
      color: var(--white) !important;
    }
  }

  &__person--red.TeamSection__person--selected {
    transition: background-color 0.6s;
    background-color: var(--red) !important;
  }
  &__person--blue.TeamSection__person--selected {
    transition: background-color 0.6s;
    background-color: var(--blue) !important;;
  }
  &__person--orange.TeamSection__person--selected {
    transition: background-color 0.6s;
    background-color: var(--orange) !important;;
  }

}


