@use "/src/styles/variables" as *;

.Section {
  min-height: 30rem;
  padding: 3rem;

  @media screen and (max-width: $s-screen){
    padding: 1.5rem;
  }

  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__content {
    width: 100%;
    max-width: $l-screen;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  &__title {
    font-weight: 400;
    font-size: 3rem;
    line-height: 3.5rem;
    margin-bottom: 2rem;
    font-family: var(--font);
    font-style: normal;
    color: var(--dark);

    p {
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 2rem;
      font-family: var(--font);
      font-style: normal;
      color: var(--dark);
    }
  }

}

/*
.Header-Client,
.List-Client,
.Team-Section,
.Yzy-Section,
.Skills-Section,
.Articles-Section,
.Blog-Actuality,
.TeamList,
.YzyDigital-Section
{
  padding-top: 115px !important;
  max-width: var(--large-screen);
  margin: auto;

  @media screen and (max-width: 1200px) {
    padding: var(--padding-inline);
  }
}

.Clients-Section-Block
{
  background: #F0F0F0;
  margin-top: 125px;
}

.Clients-Section
{
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  max-width: var(--large-screen);
  margin: auto;

  @media screen and (max-width: 1200px) {
    padding: var(--padding-inline);
    padding-bottom: 25px !important;
  }
}

.YzyDigital-Section {
  flex-wrap: wrap;

  @media screen and (max-width: 1200px) {
    flex-direction: column-reverse;
    justify-content: center;
  }
}
*/