
.YzyPlayButton {
  background-color: var(--white);
  background-image: url(/images/gradient.png);
  background-position: center;
  animation: rotate_loaders 6s infinite;
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
  padding: 0;
  background-size: cover;
  border: none;
  cursor: pointer;
  img {
    transform: translate(1px, 2px);
    transition: 500ms;
  }
}

.YzyPlayButton:hover {
  img {
    transform: translate(1px, 2px) scale(130%);
  }
}
