
$slide-width: 280px;//350;
$slide-height: 416px;//520;

.CustomerSection {

  .swiper-button-next {
    color: var(--dark);
  }
  .swiper-button-prev {
    color: var(--dark);
  }

  .swiper-slide {
    width: $slide-width;
    padding-bottom: 3rem;
  }

  &__Cover {
    background-color: var(--white);
    position: relative;
    background-position: center;
    background-size: cover;
    width: $slide-width;
    height: $slide-height;
    margin-bottom: 1.5rem;
    cursor: pointer;
  }

  &__Overlay {
    position: absolute;
    width: $slide-width;
    height: $slide-height;
    background-color: var(--grey);
    opacity: 40%;
    transition: opacity,background-color 0.3s;
  }

  &__Cover:hover .CustomerSection__Overlay{
    background-color: var(--light-grey);
    opacity: 60%;
  }

  &__CoverMask {
    position: absolute;
    width: $slide-width;
    height: $slide-height;
  }

  &__Legend {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 1.5rem;
  }

  &__Title {
    font-size: 1.5rem;
  }

  &__Short {
    grid-column : 1 / span 2;
    color: var(--dark-grey);
  }

  &__Tag {
    font-size: 1.0rem;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
}