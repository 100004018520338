@use "/src/styles/variables.scss" as *;

.MapSection {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  background-color: var(--white);

  @media screen and (max-width: $s-screen) {
    flex-direction: column;
    align-items: stretch;
  }

  /* YZY Map */
  &__container {
    flex: 3;
    align-self: stretch;
    background-color: #666666;
    aspect-ratio: 5/4 ;
    display: grid;
    grid-template-rows: 1fr;// 10rem;
    @media screen and (max-width: $s-screen) {
      flex: none;
      grid-template-rows: auto;
      aspect-ratio: 1/1 ;
    }
  }


  /* Manager Word */
  blockquote {

    display: flex;
    flex-direction: column;
    padding: 2rem;
    cite {
      margin: 2rem 0;
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
      img {
        width: 4rem;
        height: 4rem;
      }
      .MapSection__Author {
        color: var(--dark);
        display: block;
      }
      .MapSection__Job {
        color: var(--dark-grey  );
        display: block;
      }
    }
  }

  &__playButton {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    @media screen and (max-width: $s-screen) {
      display: none;
    }
  }

  &__Result__Hashtags {
    opacity: 0.8;
    padding: 0;
    margin: 0;
    margin-top: -.85rem;
    font-size: .85rem !important;
  }


  &__Quote {

    transition: 0.7s;

    flex: 2;
    min-width: 30rem;
    aspect-ratio: 16/9;

    @media screen and (max-width: $s-screen) {
      /*aspect-ratio: 3/4 ;*/
      aspect-ratio: auto;
      perspective: none;
      flex: none;
      min-width: unset;
    }


    &--big {
      aspect-ratio: 1/1;
    }

    &__Content {
      width: 100%;
      transform-style: flat;
      height: auto;
      position: initial;
    }

    &__Front, &__Back {
      width: 100%;
      background-color: var(--light-grey-40);
      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      position: initial;
      height: auto;
    }

    &__Back {
      background-image: url("/images/gradient.png");
      background-position: center;
      background-size: cover;
      display: flex;


      transform-style: initial;
      transform: none;
      padding: 2rem;

      .swiper-slide {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 0 2rem;
        gap: 1rem;

        input {
          height: 2rem;
          min-width: 200px;
          font-size: 1rem;
          font-weight: 300;
          font-family: var(--font);
          margin-bottom: 1rem;
          line-height: 1.5rem;
          padding: 5px;
          text-align: center;
          border-top: none;
          border-left: none;
          border-right: none;
          border-bottom: 0.5px solid var(--grey);
          color: var(--dark );
        }

        p {
          color: var(--white);
          font-size: 1.25rem;
          text-align: center;
        }
      }
    }
  }

  &__IA_Title {
    margin-bottom: -0.85rem;
    font-size: 1rem !important;
    opacity: 0.5;
    color: var(--dark) !important;
    font-weight: 600;
    text-align: left;
    align-self: start;
    padding-left: 0.5rem;
  }

  &__IA_Message {
    display: none;
    background-color: #15004755;
    font-size: 1rem !important;
    padding: 1rem;
    border-radius: 0.5rem;
    text-align: left !important;
  }

  &__Quote__GoBack {
    color: var(--white);
    background: transparent;
    border: none;
    opacity: 0.5;
    font-size: 0.75rem;
    position: absolute;
    z-index: 1000;
    top: 0.5rem;
    left: 0.5rem;
    cursor: pointer;
  }

  &__Quote.flipFlap &__Quote__Content {
      transform: none;
  }
  &__Quote.flipFlap &__IA_Message {
    display: block;
  }

  &__Loader {
    color: var(--white);
    font-size: 1.5rem;
    display: flex;
    height: 2rem;
    line-height: 2rem;

    img {
      animation: rotate_loaders 6s infinite;
      margin-top: -0.25rem;
      height: 3rem;
      width: 3rem;
    }

    &__Words {
      font-size: 1.5rem;
      font-weight: 500;
      color: var(--blue);
      overflow: hidden;
      span {
        display: block;
        height: 100%;
        padding-left: 0.5rem;;
        animation: spin_words 6s infinite;
      }
    }
  }
}