
$slide-width: 270px;//350;
$slide-height: 420px;//520;

.PostListSection {

  .swiper-pagination-bullet {
    background-color: var(--grey);
    opacity: 1;
  }

  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: var(--red);
    opacity: 1;
  }
  .swiper {
    margin: 0 -55px;
    width: 100%;
  }

  .swiper-button-next {
    color: var(--dark);
  }

  .swiper-button-prev {
    color: var(--dark);
  }

  .swiper-slide {
    width: $slide-width;
    height: calc($slide-height + 1rem);
  }

  &__Post {
    cursor: pointer;
    width: $slide-width;
    height: calc($slide-height);
    -webkit-box-shadow: 0 0 18px 0 rgba(0,0,0,0.1);
    box-shadow: 0 0 18px 0 rgba(0,0,0,0.1);
    padding-bottom: 3rem;
    background-color: var(--white);
  }

  &__Cover {
    position: relative;
    background-position: center;
    background-size: cover;
    width: $slide-width;
    height: $slide-height * 3/5;
  }

  &__Legend {
    padding: 1rem;
    overflow: hidden;
    height: $slide-height * 2/5;
    display: grid;
    grid-template-columns: 1fr 4.8rem;
    grid-template-rows: 4rem 1fr;
  }
  &__Date {
    text-align: right;
    color: var(--grey);
    font-size: 0.8rem;
    align-items: flex-end;
    justify-content: flex-end;
  }
  &__Title {
    font-size: 1.2rem;
  }
  &__Short {
    grid-column : 1 / span 2;
    height: 5rem;
    text-overflow: ellipsis;
    font-size: 1.0rem;
    overflow: hidden;

    display: -webkit-box !important;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    white-space: normal;
  }

  &__Buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2rem;
  }
}