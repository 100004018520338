@use "/src/styles/variables" as *;

$menu-width : 250px;

.SkillsSection {

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;

  @media screen and (max-width: $s-screen){
    grid-template-columns: 1fr;
  }

  &__Card {
    background-color: var(--white);
    -webkit-box-shadow: 0 4px 20px 0 rgba(0,0,0,0.1);
    box-shadow: 0 4px 20px 0 rgba(0,0,0,0.1);
    border-radius: 1rem;
    padding: 2rem;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;
    @media screen and (max-width: $m-screen){
      padding: 1rem;
    }
  }

  &__Header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
  }

  &__Title {
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--blue);
  }

  &__Body {
    display: flex;
    flex-direction: row;
    margin: 0 calc( -1 * (100vw - min($l-screen, 100vw - $padding*2)) / 2);
    padding-left: calc( (100vw - min($l-screen, 100vw - $padding*2)) / 2);;
    align-items: center;
    @media screen and (max-width: $m-screen){
      padding-left: 0;
      flex-direction: column;
      align-items: stretch;
    }
  }

  &__Menu {
    background-color: white;
    position: relative;
    margin: 2rem 0;
    left: calc($menu-width / 2);
    width: $menu-width;
    @media screen and (max-width: $m-screen){
      left: 2rem;
      margin: 0 -2rem;
      width: 100vw;
    }

    ul {
      display: flex;
      flex-direction: column;
      align-items: stretch;

      @media screen and (max-width: $m-screen){
        border-top: 1px solid var(--grey);
        flex-direction: row;
      }

      li {
        height: 4.5rem;
        cursor: pointer;
        padding: 1.5rem 1rem;
        border-bottom: 1px solid var(--light-grey-60);
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 1.5rem;
        position: relative;

        @media screen and (max-width: $m-screen){
          border-bottom: none;
          border-right: 1px solid var(--grey);
          flex-direction: column;
          height: auto;
          padding: 1rem;
          font-size: 1.0rem;
          text-overflow: ellipsis;
          overflow: hidden;
          flex-grow: 1;
        }

        img {
          width: 3rem;
          height: 3rem;
          margin-right: 1rem;
          position: relative;
          z-index: 2;
          @media screen and (max-width: $m-screen){
            width: 1.5rem;
            height: 1.5rem;
            margin-right: 0;
            margin-bottom: 0.2rem;
          }
        }
        span {
          position: relative;
          z-index: 2;
        }
      }
      li:last-child {
        border-bottom: none;
        @media screen and (max-width: $m-screen){
          border-right: none;
        }
      }
    }
  }


  &__Glider {
    position: absolute;
    -webkit-box-shadow: 0 4px 20px 0 rgba(0,0,0,0.1);
    box-shadow: 0 4px 20px 0 rgba(0,0,0,0.1);
    left: 0;
    width: $menu-width;
    height: 4.5rem;
    transition: transform 0.2s;

    @media screen and (max-width: $m-screen){
      display: none;
    }
  }



  &__Slider {
    @media screen and (min-width: $m-screen){
      width: calc(min($l-screen, 100vw) - $menu-width - $padding);
      padding-left: calc($menu-width / 2);
    }
    background-color: var(--light-grey-60);
    align-self: stretch;
  }

  &__Slide {
    font-size: 1rem;
    padding: 2rem;

    @media screen and (min-width: $m-screen){
      width: calc(min($l-screen, 100vw - $padding*2) - $menu-width / 2 - 20rem);
    }


    h3 {
      margin: 1rem 0;
      font-size: 2rem;

    }

  }

  &__Content {
    display: block;
    line-break: anywhere;
    font-size: 1rem;
    line-height: 1.5rem;
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal;
  }

  &__Features {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    margin: 1.5rem 0;
    overflow: auto;
    > * {

      flex: 0 0 auto;
    }
  }

  &__Feature {
    background-color: var(--white);
    -webkit-box-shadow: inset 0.75rem 0.25rem 0.5rem -0.5rem rgba(0, 0, 0, 0.1);
    box-shadow: inset 0.75rem 0.25rem 0.5rem -0.5rem rgba(0, 0, 0, 0.1);
    width: 4rem;
    height: 4rem;
    border-radius: 2rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__FeatureIcon {
    max-width: 2rem;
    max-height: 2rem;
    width: auto;
    height: auto;
    display: block;
  }

}