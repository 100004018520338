@use "/src/styles/variables" as *;
$gap : 1rem;
$duration : 90s;

.MarqueeSection {
  padding: 5rem 0;
  min-height: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: var(--blue);
  background: linear-gradient(90deg, var(--blue) 42%, var(--purple) 100%);

  &__marquee {
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: $gap;
    mask-image: linear-gradient(
        to right,
        hsl(0 0% 0% / 0),
        hsl(0 0% 0% / 1) 10%,
        hsl(0 0% 0% / 1) 90%,
        hsl(0 0% 0% / 0)
    );
  }

  &__marquee-reverse .MarqueeSection__marquee-content {
    animation-direction: reverse;
    animation-delay: -3s;
  }

  &__marquee-content {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-width: 100%;
    animation: scroll $duration linear infinite;
    font-size: 2rem;
    color: rgba(255, 255, 255, 0.4);

    @media screen and (max-width: $s-screen){
      font-size: 1.5rem;
    }

    li:after {
      content: "\2b24";
      font-size: 0.75rem;
      position: relative;
      top: -0.35rem;
      color: rgba(255, 255, 255, 0.2);
      padding: $gap;
      @media screen and (max-width: $s-screen){
        font-size: 0.50rem;
        top: -0.20rem;
        padding: calc($gap * 0.5);
      }
    }

  }


  &__title {
    font-size: 2rem;
    color: var(--white);
    margin-bottom: 1rem;
    text-align: center;
    width: 100%;
  }

}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - $gap));
  }
}